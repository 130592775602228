.bg-viewBlog {
    background-color: #06203c;
    height: auto;
    background-position: center;
    
    background-repeat: no-repeat;
    background-size: cover;
   
    
    }
    #viewBlog .mask
    {
      height: auto;
      
    }

#viewBlog #article
{
    
    background-color: #49505705;
    
}
#viewBlog h3{
    font-size: 2vh;
    font-family: 'Amiri', serif;
    margin-bottom: 0;
}
#viewBlog #article #title
{
    font-size: 6vh;
    text-align: left;
    font-weight:normal;
    font-family: 'Amiri', serif;
    margin-top: 0;
    margin-bottom: 2vh;
    

}
#viewBlog #article #description
{
    text-align: left;
    font-size: 2vh;
    font-weight: normal;
    font-family: 'Amiri', serif;
}
#viewBlog #post .fa-2x
{
    font-size: 5vh;
    margin-right: 1vh;
}
#viewBlog #post .fa-1x
{
    font-size: 2.5vh;
}
#viewBlog .socials
{
    width: 50%;
}
 .info
{
    font-family: 'Amiri', serif;
    font-size: 1.5vh;
    color: #6c757d;
    min-height: 1.5vh !important;
}
#viewBlog img
{
    width: 100%;
    max-width: 100%;
    display: flex;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
}
#viewBlog #article header
{
    padding-top: 5vh;
    padding-bottom: 5vh;
}
#viewBlog .markdown h1
{
    font-size: 2.2vh;
    font-weight: bold;
}
#viewBlog .markdown h2
{
    font-size: 3vh;
    font-weight: normal;
}
#viewBlog .markdown blockquote
{
    border-left: solid 0.2vh #f7cd52 ;
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-left: 2vh;
    font-size: 2vh;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 3vh;
    margin-bottom: 3vh;
    color: #6c757d;
}
#viewBlog .markdown p, #viewBlog .markdown li
{
    font-family: 'Amiri', system-ui;
    font-weight: normal;
    line-height: 3vh;
    font-size: 1.8vh;
    
}
#viewBlog .markdown pre
{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    
}
#viewBlog .markdown img
{
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 4vh;
}
#viewBlog .markdown code
{
    
    padding: 4vh;
    line-height: 3vh;
    color: #ffcd52;
    background-color: black;
    overflow: scroll;
    
}
#viewBlog #post
{
    
}

#viewBlog #post #backToBlog
{
    font-size: 1.5vh;
    padding-top: 4vh;
    color: black;
}
#viewBlog #post #backToBlog:hover
{
    color: #f7cd52;
}
#viewBlog figure
{
    margin-top: 5vh;
}
#viewBlog article
{
    margin-top: 3vh;
}
#viewBlog .markdown a
{
    color: #ffcd52;
}
#viewBlog .markdown a:hover
{
    color: #A01F24;
}
hr
{
    border-color: #f7cd52;
}
#viewBlog table
{
    border-collapse: collapse;
    width: 100%;
    font-family: 'Amiri' serif;
    margin-top: 5vh;
    margin-bottom: 5vh;
    
}
th
{
    font-weight: bold !important;

}
 td, th {
    border: 1px solid #ddd;
    padding: 8px;
    font-weight: normal;
  }
  
tr:nth-child(even)
{
    background-color: #ffcb2657;
}
  
tr:hover 
{
    background-color: #ffcb2657;
}
  
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffcd52;
    color: #A01F24;
  }
#viewBlog #recent-blog
{
    
    margin-top: 5vh;
    margin-bottom: 5vh;
    border-top: solid 0.2vh #ffcd52;
    border-bottom: solid 0.2vh #ffcd52;
    padding: 2vh;
    

}
#viewBlog #recent-blog h2
{
    font-size: 2vh;
    font-family: 'Amiri', serif;
    margin-bottom: 2vh;
}
#blogComponent 
{
    min-height: 40vh;
    border-bottom: solid 0.2vh #ffcd52;
    margin-left: 0.5vh;
    margin-right: 0.5vh;
    padding-top: 3vh;
    height: 100%;

}
#viewBlog .react-reveal
{
    height: 100%;
}
#blogComponent h3
{
    color: white;
    font-size: 2vh;
    margin-bottom: 0;
}
#viewBlog #blogComponent h3
{
    color: black;
}
#blog #blogComponent h3
{
    color: black;
}
#blogComponent h1
{
    
    margin-top: 0;
    margin-bottom: 2vh;
    overflow-y: hidden;
    text-overflow: ellipsis;
    font-size: 3vh;
    font-family: 'Amiri', serif;
    
    
}
button:focus
{
    outline: none;
}
#blogComponent img
{
    max-height: 25vh;
    min-height: 20vh;
}
#blogComponent p
{
    
}
#viewBlog #blogComponent .row a, #viewBlog #seeMore
{
  font-size: 1.5vh !important;
  color: black;
  font-family: 'Amiri', serif;
}
#viewBlog #blogComponent .row a:hover, #viewBlog #seeMore:hover
{
  color: #ffcb26;
  transform: translateX(10px);
}
#viewBlog #seeMore
{
    margin-bottom: 2vh;
}
@media(min-width: 768px)
{
    #viewBlog h3{
        font-size: 2vh;
        font-family: 'Amiri', serif;
        margin-bottom: 0;
    }
    #viewBlog #article #title
    {
        font-size: 6vh;
        text-align: left;
        font-weight:normal;
        font-family: 'Amiri', serif;
        margin-top: 0;
        margin-bottom: 2vh;
        
    
    }
    #viewBlog #article #description
    {
        text-align: left;
        font-size: 2vh;
        font-weight: normal;
        font-family: 'Amiri', serif;
    }
    #viewBlog #post .fa-2x
    {
        font-size: 5vh;
        margin-right: 1vh;
    }
    #viewBlog #post .fa-1x
    {
        font-size: 2.5vh;
    }
    #viewBlog .socials
    {
        width: 50%;
    }
     .info
    {
        font-family: 'Amiri', serif;
        font-size: 1.5vh;
        color: #6c757d;
        min-height: 1.5vh !important;
    }
    #viewBlog img
    {
        width: 100%;
        max-width: 100%;
        display: flex;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
    }
    #viewBlog #article header
    {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    #viewBlog .markdown h1
    {
        font-size: 2.2vh;
        font-weight: bold;
    }
    #viewBlog .markdown h2
    {
        font-size: 3vh;
        font-weight: normal;
    }
    #viewBlog .markdown blockquote
    {
        border-left: solid 0.2vh #f7cd52 ;
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 2vh;
        font-size: 2vh;
        font-style: italic;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 3vh;
        margin-bottom: 3vh;
        color: #6c757d;
    }
    #viewBlog .markdown p, #viewBlog .markdown li
    {
        font-family: 'Amiri', system-ui;
        font-weight: normal;
        line-height: 3vh;
        font-size: 1.8vh;
        
    }
    #viewBlog .markdown pre
    {
        display: flex;
        justify-content: center;
        margin-bottom: 3vh;
        margin-top: 5vh;
        margin-bottom: 5vh;
        
    }
    #viewBlog .markdown img
    {
        width: 100%;
        margin-top: 2vh;
        margin-bottom: 4vh;
    }
    #viewBlog .markdown code
    {
        
        padding: 4vh;
        line-height: 3vh;
        color: #ffcd52;
        background-color: black;
        
    }
    #viewBlog #post
    {
        
    }
    #viewBlog #post #backToBlog
    {
        font-size: 1.5vh;
        padding-top: 4vh;
        color: black;
    }
    #viewBlog #post #backToBlog:hover
    {
        color: #f7cd52;
    }
    #viewBlog figure
    {
        margin-top: 5vh;
    }
    #viewBlog article
    {
        margin-top: 3vh;
    }
    #viewBlog .markdown a
    {
        color: #ffcd52;
    }
    #viewBlog .markdown a:hover
    {
        color: #A01F24;
    }
    hr
    {
        border-color: #f7cd52;
    }
    #viewBlog table
    {
        border-collapse: collapse;
        width: 100%;
        font-family: 'Amiri' serif;
        margin-top: 5vh;
        margin-bottom: 5vh;
        
    }
    th
    {
        font-weight: bold !important;
    
    }
     td, th {
        border: 1px solid #ddd;
        padding: 8px;
        font-weight: normal;
      }
      
    tr:nth-child(even)
    {
        background-color: #ffcb2657;
    }
      
    tr:hover 
    {
        background-color: #ffcb2657;
    }
      
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #ffcd52;
        color: #A01F24;
      }
    #viewBlog #recent-blog
    {
        width: 95vh;
        margin-top: 5vh;
        margin-bottom: 5vh;
        border-top: solid 0.2vh #ffcd52;
        border-bottom: solid 0.2vh #ffcd52;
        padding: 2vh;
    
    }
    #viewBlog #recent-blog h2
    {
        font-size: 2vh;
        font-family: 'Amiri', serif;
        margin-bottom: 2vh;
    }
    #blogComponent 
    {
        min-height: 40vh;
        border-bottom: solid 0.2vh #ffcd52;
        margin-left: 0.5vh;
        margin-right: 0.5vh;
    }
    #blogComponent h3
    {
        color: white;
        font-size: 2vh;
        margin-bottom: 0;
    }
    #viewBlog #blogComponent h3
    {
        color: black;
    }
    #blog #blogComponent h3
    {
        color: black;
    }
    #blogComponent h1
    {
        
        margin-top: 0;
        margin-bottom: 2vh;
        overflow-y: hidden;
        text-overflow: ellipsis;
        font-size: 3vh;
        font-family: 'Amiri', serif;
        
        
    }
    button:focus
    {
        outline: none;
    }
    #blogComponent img
    {
        max-height: 25vh;
        min-height: 20vh;
    }
    #blogComponent p
    {
        
    }
    #viewBlog #blogComponent .row a, #viewBlog #seeMore
    {
      font-size: 1.5vh !important;
      color: black;
      font-family: 'Amiri', serif;
    }
    #viewBlog #blogComponent .row a:hover, #viewBlog #seeMore:hover
    {
      color: #ffcb26;
      transform: translateX(10px);
    }
    #viewBlog #seeMore
    {
        margin-bottom: 2vh;
    }
    
    
}
