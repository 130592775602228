


.bg-blog {
    background-image: url('../images/AdobeStock_332215667.jpeg');
    height: auto;
    background-position: center;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    
    }
.bg-portfolio {
        background-image: url('../images/AdobeStock_332404854.jpeg');
        height: auto;
        background-position: center;
        
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        
        }
    #blog .mask
    {
      height: auto;
      
    }

#blog .mask h1{
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 3vh;
    padding-right: 3vh;
    text-align: center;
    
    font-size: 4.5vh;
    font-family: 'Amiri', serif;

}
.portfolioList{
    box-shadow: 5px 24px 56px 100px #ffffff;
}

#blog #blogComponent
{
    padding-top: 5vh;
    padding-bottom: 1vh !important;
    border-bottom: solid 0.1vh #f7cd52;
    margin-left: 15px;
    margin-right: 15px;
    height: 100%;
}
#blog #blogComponent a
{
    font-size: 1.3vh;
    color: black;
}
#blog #blogComponent .row a:hover
{
    color: #ffcb26;
    transform: translateX(10px);
}
#blog #blogComponent .row
{
    width: 100%;
    
}
#blog #blogComponent img
{
    max-height: 25vh;
}

#blog #blogList li
{
    margin-left: 2vh;
}
#blog #blogList h1
{
    margin-top: 0vh ;
    margin-bottom: 2vh;
    text-align: left !important;
    
    
}
#blog #blogList .fa-1x
{
    font-size: 2.5vh;
}
#blog #blogList .nav-link
{
    color: black !important;
    font-size: 2vh;
    font-weight: normal;
}
#blog #blogList .nav-link:hover, #blog #blogList h1:hover{
    color: #f7cd52 !important;
}
#blog #blogList .nav-item
{
    margin-left: 0vh !important;
}
#blog #blogList #navContainer
{
    margin-left: 0vh;
    margin-top: 5vh;
}
#blog #blogList .show
{
    border-bottom: solid 0.1vh #f7cd52;
    padding-bottom: 1vh;
}
#blog #blogContainer .message
{
    padding-top: 5vh;
    padding-left: 4vh;
    font-size: 3vh;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
#portfolioHeader
{
    margin-top: 3vh;
    margin-bottom: 0vh;
    padding-bottom: 1vh;
    border-bottom: solid 0.2vh #ffcb26;
    font-family: serif;
    padding-left: 1vh;
    padding-right: 1vh;
    font-weight: 400;
    font-size: 2.5vh;
}

@media (min-width: 576px)  {

}

@media (min-width: 768px)  {
    #portfolioHeader
{
    margin-top: 3vh;
    margin-bottom: 0vh;
    padding-bottom: 1vh;
    border-bottom: solid 0.2vh #ffcb26;
    font-family: serif;
    padding-left: 1vh;
    padding-right: 1vh;
    font-weight: 400;
}
.portfolioList{
    box-shadow: 5px 24px 101px 154px #ffffff;
}
}

@media (min-width: 992px)  {
    #blog #blogList .nav-link
    {

        font-size: 1vh;
    }
    #blog #blogList #blogComponent img
    {
        min-height: unset;
    }
    #blog #blogList #blogComponent h3, #blog #blogList #blogComponent h1
    {
        font-size: 2vh;
    }
    #blog #blogList #blogComponent h3{
        font-size: 1.2vh;
    }

    #blog  .info
    {
        font-size: 0.8vh;
    }
}

@media (min-width: 1200px) {

#blog .mask h1{
    padding-top: 25vh;
    padding-bottom: 25vh;
    padding-left: 3vh;
    padding-right: 3vh;
    text-align: center;
        
    font-size: 5.5vh;
    font-family: 'Amiri', serif;
    
    }

#blogContainer
{
    margin-bottom: 5vh;
   
    
}
#blog #blogList #navContainer
{
    margin-right: 30vh;
    
    margin-top: 3vh;
    width: fit-content;
}
#blog #blogList .nav-link
{
    color: black !important;
    font-size: 1.5vh;
    font-weight: normal;
}
#blog #blogList .nav-link:hover, #blog #blogList h1:hover{
    color: #f7cd52 !important;
}
#blog #blogList .nav-item
{
    margin-left: 0vh !important;
}

#blog #blogList #navbarCollapse
{
    border-bottom: solid 0.1vh #f7cd52;
    padding-bottom: 1vh;
}
#blog #blogList h1
{
    margin-top: 0vh ;
    margin-bottom: 2vh;
    text-align: left !important;
    font-size: 2.3vh;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
}
#blog #blogList p
{
    font-size: 1.3vh;
    
}

}
@media(hover: none)
{
    .bg-blog, .bg-portfolio
    {
        background-attachment: unset;
    }
}
.blogAd
{
    max-width: 350px;
    max-height: 90px;
}
@media(min-width:768px)
{
    .blogAd
    {
        max-width: 165px;
        max-height: unset;
    }
}