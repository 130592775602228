.bg-portfolio
{
    background-image: url('../images/AdobeStock_332404854.jpeg');
    background-attachment: fixed;

    background-position: center;
  
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-portfolio .mask
{
    height: 100vh;
}
#blog #blogList #blogComponent img{
  max-height: none !important;
  min-height: none !important;
}
@media(min-width: 992px)
{
  #blog .portfolioList #blogComponent img{
    max-height: none !important;
    min-height: none !important;
}
}