
@import-normalize; /* bring in normalize.css styles */
/* rest of app styles */
.App {
  text-align: center;
}

.App-logo {
  
  pointer-events: none;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: button-spin 1s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.rgba-black-strong
{
  background-color: #06203cc4;
} 

body, html {
  height: 100%;
  background-color: white !important;
  line-height: unset !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  
  }
  .bg {
  background-image: url('../images/BackgroundFinal\(1\).jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  }
  .mask
  {
    height: 100%;
    
  }
  .down-btn, .dropdown-btn
  {
    border: none;
    background-color: none;
  }
  #down-btn, .dropdown-btn
  {
    margin: 0rem;
    padding: 0rem;
    box-shadow: none !important;
    width: fit-content;
    
  }
  
  html{
    scroll-behavior: smooth;
   
  }
  .navbar
  {
    box-shadow: none;
    position: unset;
    
  }
  .navbar-nav
  {
    text-align: center;
  }
  .collapse .navbar-collapse
  {
    margin-top: 0em;
    
  }
  .nav-color
  {
    background-color: #06203c !important
  }
  body
  {
    line-height: 3.5;
  }

.navbar.scrolling-navbar{
  padding-top: 0em;
}
h1
{
  color: white;
}

.nav-link
{
  font-weight: normal !important;
  padding-left: 3vh !important;
  
}
.navbar.navbar-dark .navbar-nav .nav-item .nav-link{
  color: #ffcb26;
}

.nav-link:hover
{
  color: white !important;
}
.navbar-brand
{
  font-size: 2vh;
  padding-right: 10vh;
}
#mynav .fa-2x
{
  font-size: 2vh;
  line-height: 4vh;
}
div p, h1
  {
    color: black;
  
  }
  #welcome-container p
  {
    font-size: 0.5rem;
    margin-bottom: 0em;
    line-height: 0em;
    
  }
  #seemore
  {
    color: white;
  }
  
  #welcome-container
  {
    
    padding-top: 20vh;
    height: 45vh;
    
    
  }
  #button-container
  {
    height: 36vh;
   
  }
  .Typist{
    font-family: athelas;
    font-size: 3rem;
    line-height: normal;
    padding-top: 1rem;
  }
  #welcome-container p{
    font-family: 'Cinzel', serif;
    font-size: 1.5rem;
    padding-bottom: 0rem;
    line-height: 3rem;
  }
  #subtitle {
    font-size: 1rem !important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: normal;
    color:#f7d406;
    text-align: center;
    padding-top: 5vh;
  }
  #profile
  {
    height: 39vh;
    width: 39vh;
    margin-top: 0rem;
    margin-bottom: 4rem

  }
  .text
  {
    color: #A01F24;
    margin-bottom: 0em;
    line-height: 2.2rem;
    font-size: 0.8rem;
    
  }
  #about
  {
    background-color: rgb(255, 255, 255);
    background-image: url('../images/about1\ copy.jpg');
    background-position: -123px 0px !important;
    
    background-repeat: no-repeat;
    background-size: 283%;
   
    
    padding-top: 0rem;
    padding-bottom: 0em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 00% 0% 00% 0%;
    
  }
  #about strong{
    color: #ffcb26;
    font-weight: normal;
    font-size: 2.3vh;
  }
  .profileText .headers, .profileText h2, .profileText p
  {
    color: #ffcb26;
  }
  .profileText p
  {
    font-size: 2.3vh;
    line-height: 4.5vh;
    
    color: white;
  }
  .profileText .headers
  {
    margin-bottom: 1vh;
  }
  .profileText h2
  {
    margin-bottom: 3vh;
  }

  .profileText
  {
    padding-top: 10vh;
    padding-bottom: 5vh;
    padding-left: 3vh;
    padding-right: 3vh;
    width: 100vw;
  }
  .headers
  {
    color: white;
    font-size: 1em;
    margin-bottom: 1.5rem;
    text-align: start;
    
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    width: fit-content;
    border-bottom: solid 0.2vh #ffcb26;
    padding-right: 2vh;
    padding-bottom: 1vh;
  }
  h2 
  {
    font-size: 1.5em;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
    
  #skills
  {
    background-image: url('../images/AdobeStock_305233591.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0% 0% 0% 0%;
  }
  #skills .mask
  {
    padding-left: 3vh;
    padding-right: 3vh;
  }
  /* #skills .headers
  {
    font-size: 1em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  } */
  
  #skills .skillContainer{
    padding: 2vh;
    background-color: #fec826;
    margin: 1vh;
  }
  #skills .skillContainer h3, #skills .skillContainer p, #skills .skillContainer i{
    margin: 0;
    padding: 0;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  }
  #skills h3
  {
    color: rgb(255, 255, 255);
    text-align: start;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  #skills .skillContainer p
  {
    color: #a02024;
  }
  #skills h1
  {
    color: rgb(255, 255, 255);
    text-align: start;
    font-weight: bold;
    padding-top: 5rem;
    
    
  }
  #skills h2
  {
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    text-align: center;
    
  }
  #skills .row
  {
    margin-top: 1em;
    
  }
  #instagram-btn
  {
    margin: 0rem;
    padding: 0.5rem;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
   margin-top: 0rem;
   margin-left: 0rem;
   box-shadow: unset;
   
   
   
  }
  .fa-2x
  {
    color: white;
    line-height: 4vh !important;
  }
  #email
  {
    color: #ffcb26;
    margin-left: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    
    padding-top: 1rem;
    padding-bottom: 1rem;
    
  }
  #email:hover
  {
    
    color: white;
  }
  #interestsBanner
  {
    background-color: white;
   
   margin-top: 0rem;
    border-radius: 0% 0% 0% 0%;
    

  }
  .innerContainers1
  {
    background-color:transparent ;
    
    
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    text-align: center;
    margin-top: 4vh;
   
  }
  .innerContainers2
  {
    background-color:transparent ;
    
    
    margin-bottom: 0rem;
    margin-top: 0rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    text-align: center;
  }

  .interestHeaders
{
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #ffcb26;

}
.bg-warning
{
  background-color: #ffb300;
}
.blockquote
{
  border:unset;
 

}
.blockquote p
{
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  
}
#grey
{
  background-color: rgb(63, 61, 61);
  
  
}
#quote
{
  background-color: white;
  
  
  margin-left: 18rem;
  margin-right: 18rem;
  
  
}
.blockquote-footer
{
  margin-bottom: 0rem !important; 
  color: rgb(255, 193, 36);
}
.card-text
{
  line-height: 2rem;
  color: white;
}
#recentBlog
{
  background-color: #0a1d2f;
}
#recentBlog .react-reveal
{
  height: 100%;
}
/* #recent-blog-text
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-weight: normal;
  font-size: 1.5rem;
  
  margin-bottom: 2rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-top: solid 0.2rem #ffb300;
}
#recent-blog-text strong
{
  font-size: 3rem !important;
} */
#recent-blog-text
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-weight: normal;
  font-size: 4rem;
  
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-top: unset;
}
#recentBlog .mask
{
  padding-top: 15vh;
  padding-bottom: 15vh;
  margin-left: 3vh;
  margin-right: 3vh;
}
#recentBlog .projectSection
{
  border-top: solid 0.1vh #f7cd52;
}
#recentBlog #blogComponent
{
    padding-top: 5vh;
    padding-bottom: 1vh !important;
    border-bottom: solid 0.1vh #f7cd52;
    margin-left: 15px;
    margin-right: 15px;
    height: 100%;
}
#recentBlog #blogComponent h1, #recentBlog #blogComponent li, #recentBlog #blogComponent p
{
  color: white;
}

#recentBlog h1
{
  margin-top: 2vh;
  font-size: 3vh;
}
#recentBlog li
{
  margin-left: 1vh;
}
#recentBlog a{
  color: white;
  font-size: 2vh;
  font-family: 'Amiri', serif;
  margin-top: 0vh;
}
#recentBlog a:hover
{
  color: #ffcb26;
  transform: translateX(10px);
}
.card-container
{
  
  padding-bottom: 4rem;
  
}

.card-container
{
  
  padding-bottom: 2rem;
  
}
.blogRow
{
  margin-left: 1rem;
  margin-right: 1rem;
}
.card-mask:hover
{
  background-color: black;
  opacity: 0.8;
}
#education h2
{
  font-size: 2rem;
  font-weight: normal;
  text-align: start;
  font-family: 'Cormorant', serif;
}
#education strong
{
  font-size: 4.5rem;
  font-weight: bold;
  
  font-family: 'Rye', cursive;
}
#education p
{
  line-height: 2rem;
  padding-bottom: 6rem;
  font-size: 1rem;
}
#education
{
  background-color: #0a1d2f;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 0% 0% 0% 0%;
}
#education .col-12
{
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
#education .educationIcons
{
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  
}
#education p, #education .headers
{
  color: white;
}
#education strong
{
  color: #ffcb26;
}
#recentPortfolio strong, #recentPortfolio p
{
  color: white;
}
#recentPortfolio h2
{
  font-size: 3rem;
  padding-top: 6rem !important;
  border-top: solid 0.2rem rgb(255, 193, 36);
  margin-bottom: 2rem !important;
}
#recentPortfolio
{
  padding-bottom: 8rem;
}
#description
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
}
#contact
{
  background-image: url('../images/AdobeStock_248175189.jpg');
  height: auto !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
#contact .mask
{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem ;

}
#contact h2
{
  color: white;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  margin-bottom: 2rem;
  margin-top: 2rem;
  
}
#contact p
{
  margin-bottom: 2rem;
  line-height: 2rem;
  font-size: 2.5vh;
  color: #ffcb26;
  font-family: 'Amiri', serif;
}
#contact label
{
  margin-bottom: 0rem;
}
#contact span 
{
  color: red;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  
  
}

#socialsBanner h1
{
  padding-top: 4vh;
  font-size: 2rem !important;
  font-family: 'Amatic SC', cursive;
  color: white;

}
#socialsBanner .col-12
{
  border: none !important;
  height: 15vh !important;
}
#footer
{
  background-image: url('../images/AdobeStock_212352434\ copy.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  
  
}
#footer .mask
{
  height: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
#footer h2 
{
  color: white;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

#footer p 
{
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
#formPadding
{
  padding-left: 0rem !important;
}
#footer h1
{
  font-family: 'Sacramento', cursive;
  color: white;
  margin-top: 3rem;
  font-size: 2rem;
}
#subscribe{
  background-color: #06203c;
  padding-top: 10vh;
  padding-bottom: 2vh;
}
#subscribe p {
  color: #fec826;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7vh;
  line-height: 2.5vh;

}
#subscribe h1{
  color: white;
  font-size: 2.5vh;
  font-family: 'Open Sans', sans-serif;
}
#subscribe .col-12{
  padding-left: 5vh;
  padding-right: 5vh;
}
#subscribe ul
{
  padding-left: 0vh;
  display: flex;
  flex-direction: column;
}
#subscribe .footerSocials
{
  color: #fec826;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7vh;
  margin-bottom: 1vh;
}

#subscribe label {
  color: #fec826;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7vh;
  margin-bottom: 2vh;
  text-align: center;
}
#subscribe .btn {
  background-color: transparent !important;
  border: 0.2vh solid #fec826;
  color: #fec826;
  margin-top: 2vh;
}
#subscribe .footerLinks
{
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7vh;
  margin-bottom: 1vh;
  margin-top: 2vh;
}
#subscribe .footerLinks:hover, #subscribe .footerSocials:hover{
  color: #74191c;
}
#subscribe .copywrite
{
  margin-top: 2vh;
  text-align: center;
}
#subscribe span {
  margin-top: 2vh;
  font-size: 1.2vh;
  color: #A01F24;
}
#subscribe input{
  color: #A01F24 !important; 
}
#socialsBanner
{
  background-color: #06203c;
}

@media (min-width: 576px) { 
  div p, h1
  {
    color: rgb(0, 0, 0);
  
  }
  #welcome-container p
  {
    font-size: 0.5rem;
    margin-bottom: 0em;
    line-height: 0em;
    
  }
  #seemore
  {
    color: white;
  }
  
  #welcome-container
  {
    padding-left: 5vh;
    padding-top: 15vh;
    height: 45vh;
    
    
  }
  #button-container
  {
    height: 45vh;
   
  }
  .Typist{
    font-family: athelas;
    font-size: 5rem;
    line-height: normal;
  }
  #welcome-container p{
    font-family: 'Cinzel', serif;
    font-size: 2rem;
    padding-bottom: 0rem;
    line-height: 3rem;
  }
  #subtitle {
    font-size: 1rem !important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: normal;
    color:#f7d406;
    text-align: center;
  }
  #profile
  {
    height: 25rem;
    width: 25rem;
    margin-top: 0rem;
    margin-bottom: 4rem

  }
  .text
  {
    color: rgb(0, 0, 0);
    margin-bottom: 0em;
    line-height: 2.2rem;
  }
  #about
  {
    background-color: rgb(255, 255, 255);
    padding-top: 6rem;
    padding-bottom: 4em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 00% 0% 00% 0%;
    
  }
  #about p
  {
    font-size: 1.5vh;
  }
  .headers
  {
    font-size: 1.5em;
    margin-bottom: 2rem;
    text-align: start;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  h2 
  {
    font-size: 2em;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
    
  #skills
  {
    background-image: url('../images/AdobeStock_305233591.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0% 0% 0% 0%;
  }
  /* #skills .headers
  {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  } */
  #skills .container
  {
    padding-left: 0rem;
  }
  #skills h3
  {
    color: rgb(255, 255, 255);
    text-align: start;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  #skills h1
  {
    color: rgb(255, 255, 255);
    text-align: start;
    font-weight: bold;
    padding-top: 3rem;
    
  }
  #skills h2
  {
    color: rgb(255, 255, 255);
    
    
  }
  #skills .row
  {
    margin-top: 1em;
    
  }
  #instagram-btn
  {
    margin: 0rem;
    padding: 1rem;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
   margin-top: 1em;
   margin-left: 0rem;
   
  }
  #email
  {
    color: black;
    margin-left: 0.5rem;
    font-weight: bold;
    font-size: 1.3rem;
    
    padding-top: 1rem;
    
  }
  #email:hover
  {
    
    color: #ffb300;
  }
  #interestsBanner
  {
    background-color: white;
   
   margin-top: 0rem;
    border-radius: 0% 0% 0% 0%;

  }
  #interestsBanner .innerContainers1
  {
    background-color:rgb(255, 255, 255) ;
    border-radius: 5% 5%;
    box-shadow: 0px 0px 20px 0px rgb(170, 170, 170);
   
  }
  #interestsBanner .innerContainers2
  {
    background-color:rgb(255, 193, 36) ;
    border-radius: 5% 5%;
    box-shadow: 0px 0px 20px 0px rgb(170, 170, 170);
    margin-bottom: 8rem;
    margin-top: 8rem;
  }

  .interestHeaders
{
  margin-top: 1rem;
  font-size: 1.5rem;

}
.bg-warning
{
  background-color: #ffb300;
}
.blockquote
{
  border:unset;
 

}
.blockquote p
{
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  
}
#grey
{
  background-color: rgb(63, 61, 61);
  
  
}
#quote
{
  background-color: white;
  
  
  margin-left: 18rem;
  margin-right: 18rem;
  
  
}
.blockquote-footer
{
  margin-bottom: 0rem !important; 
  color: rgb(255, 193, 36);
}
.card-text
{
  line-height: 2rem;
  color: white;
}
#recent-blog-text
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-weight: normal;
  font-size: 4rem;
  
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-top: solid 0.2rem #ffb300;
}


.card-container
{
  
  padding-bottom: 4rem;
  
}
.card-mask:hover
{
  background-color: black;
  opacity: 0.8;
}
#education h2
{
  font-size: 3rem;
  font-weight: normal;
  text-align: start;
  font-family: 'Cormorant', serif;
}
#education strong
{
  font-size: 6rem;
  font-weight: bold;
  
  font-family: 'Rye', cursive;
}
#education p
{
  line-height: 2rem;
  padding-bottom: 6rem;
}
#education
{
  background-color: #0a1d2f;
  padding-top: 8rem;
  padding-bottom: 6rem;
  border-radius: 0% 0% 0% 0%;
}
#recentPortfolio strong, #recentPortfolio p
{
  color: white;
}
#recentPortfolio h2
{
  font-size: 3rem;
  padding-top: 6rem !important;
  border-top: solid 0.2rem rgb(255, 193, 36);
  margin-bottom: 2rem !important;
}
#recentPortfolio
{
  padding-bottom: 8rem;
}
#description
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
}
#contact
{
  background-image: url('../images/AdobeStock_248175189.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
#contact .mask
{
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 5rem;
  padding-bottom: 5rem ;
}
#contact h2
{
  color: white;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  margin-bottom: 2rem;
  margin-top: 2rem;
  
}
#contact p
{
  margin-bottom: 4rem;
  line-height: 2rem;
  font-size: 1.3rem;
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#contact label
{
  margin-bottom: 0rem;
}
#contact span 
{
  color: red;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  
  
}
#socialsBanner h1
{
  font-size: 3rem !important;
  font-family: 'Amatic SC', cursive;

}
#footer
{
  background-image: url('../images/AdobeStock_212352434\ copy.jpg');
  height: auto !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
#footer .mask
{
  height: auto;
  padding-top: 10rem;
  padding-bottom: 10rem;
}
#footer h2 
{
  color: white;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

#footer p 
{
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
#formPadding
{
  padding-left: 0rem !important;
}
#footer h1
{
  font-family: 'Sacramento', cursive;
  color: white;
  margin-top: 3rem;
  font-size: 2rem;
}
#footer #instagram-btn
{
  
}

}

@media (min-width: 768px) { 
  

.nav-link
{
  font-weight: normal !important;
  padding-left: 2vh !important;
  padding-right: 0vh !important;
}
.nav-link:hover
{
  color: white !important;
}
.navbar-brand
{
  font-size: 2vh;
  padding-right: 1vh;
}
#mynav .fa-2x
{
  font-size: 2vh;
  line-height: 0vh;
}
  div p, h1
  {
    color: rgb(0, 0, 0);
  
  }
  #welcome-container p
  {
    font-size: 0.5rem;
    margin-bottom: 0em;
    line-height: 0em;
    
  }
  #seemore
  {
    color: white;
  }
  
  #welcome-container
  {
    padding-left: 0vh;
    padding-top: 15vh;
    height: 45vh;
    
    
  }
  #button-container
  {
    height: 45vh;
   
  }
  .Typist{
    font-family: athelas;
    font-size: 4.7rem;
    line-height: normal;
  }
  #welcome-container p{
    font-family: 'Cinzel', serif;
    font-size: 2rem;
    padding-bottom: 0rem;
    line-height: 6rem;
  }
  #subtitle {
    font-size: 2rem !important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: normal;
    color:#f7d406;
  }
  #profile
  {
    height: 25rem;
    width: 25rem;

  }
  .text
  {
    color: rgb(0, 0, 0);
    margin-bottom: 0em;
    line-height: 2.2rem;
  }
  #about
  {
    background-image: url('../images/about1\ copy.jpg');
    background-position: -21vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
    position: sticky !important;
    
    background-color: #56575b;
    padding-top: 0vh;
    padding-bottom: 0em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 00% 0% 00% 0%;
    height: 90vh;
  }
  #about p 
  {
    font-size: 2vh;
  }
  #about strong{
    color: #A01F24;
    font-weight: normal;
    font-size: 2.2vh;
    text-decoration: underline;
  }
  #about strong:hover{
    
    text-decoration: none;
  }
  .profileText .headers, .profileText h2
  {
    color: black;
  }
  .profileText p
  {
    color: #A01F24;
  }
  .headers
  {
    padding-top: 1rem !important;
    font-size: 1.5em;
    margin-bottom: 2vh;
    text-align: start;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: 'Amiri', serif;
    border-bottom: solid 0.2vh #ffcb26;
    padding-bottom: 1vh;
    padding-right: 3vh;
    width: fit-content;
    margin-left: 0vh  !important;
  }
  h2 
  {
    font-size: 2.5vh;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
  }
  .profileText
  {
    
    background-color: #ffcb26;
    padding-top: 15vh;
    padding-left: 3vh;
    padding-right: 3vh;
    padding-bottom: 10vh;
    width: 45vh;
    margin-right: 1vh;
  }
  h2 
  {
    font-size: 2em;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
    
  #skills
  {
    background-image: url('../images/AdobeStock_305233591.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0% 0% 0% 0%;
  }
  /* #skills .headers
  {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  } */
  #skills .mask
  {
    padding-top: 15vh;
    padding-bottom: 15vh;
    padding: 15vh;
  }
  /* #skills .headers
  {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 1rem;
  } */
  #skills .container
  {
    padding-left: 0rem;
    padding-top: 1rem;
    padding-right: 0rem;
  }
  #skills h3
  {
    color: rgb(255, 255, 255);
    text-align: start;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  #skills h1
  {
    color: rgb(255, 255, 255);
    text-align: start;
    font-weight: bold;
    padding-top: 1.5rem;
    
  }
  #skills h2
  {
    color: rgb(255, 255, 255);
    margin-top: 1rem;
    font-size: 2vh;
    text-align: start;
    
    
  }
  #skills .row
  {
    margin-top: 0em;
    
  }
  #instagram-btn
  {
    margin: 0rem;
    padding: 0.5rem;
    border-radius: 50%;
    width: fit-content;
   margin-top: 2.5vh;
   
  }
  #email
  {
    color: black;
    margin-left: 0rem;
    font-weight: bold;
    font-size: 1.5vh;
    width: fit-content;
    line-height: 7vh;
    
  }
  #socialEmail
  {
    width: fit-content;
  }
  #email:hover
  {
    
    color: #A01F24;
  }
  #interestsBanner
  {
    background-color: white;
   
   
    border-radius: 0% 0% 0% 0%;

  }
  .innerContainers1
  {
    width: 20vw;
    
  }
  #interestsBanner .innerContainers2
  {
    background-color:rgb(255, 193, 36) ;
    border-radius: 5% 5%;
    box-shadow: 0px 0px 20px 0px rgb(170, 170, 170);
  }

  .interestHeaders
{
  margin-top: 1rem;
  font-size: 2vh;

}
.bg-warning
{
  background-color: #ffb300;
}
.blockquote
{
  border:unset;
 

}
.blockquote p
{
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  
}
#grey
{
  background-color: rgb(63, 61, 61);
  
  
}
#quote
{
  background-color: white;
  
  
  margin-left: 18rem;
  margin-right: 18rem;
  
  
}
.blockquote-footer
{
  margin-bottom: 0rem !important; 
  color: rgb(255, 193, 36);
}
.card-text
{
  line-height: 2rem;
  color: white;
}
#recent-blog-text
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-weight: normal;
  font-size: 4rem;
  
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-top: solid 0.2rem #ffb300;
}
#recentBlog #blogComponent a 
{
  font-size: 1.5vh !important;
}
#recentBlog a:hover
{
  color: #ffcb26;
  transform: translateX(10px);
}
#recentBlog #blogComponent h1
{
  font-size: 1.8vh;
}

.card-container
{
  
  padding-bottom: 4rem;
  
}
.card-mask:hover
{
  background-color: black;
  opacity: 0.8;
}
#education h2
{
  font-size: 3rem;
  font-weight: normal;
  text-align: start;
  font-family: 'Cormorant', serif;
  color: white;
}
#education strong
{
  font-size: 4rem;
  font-weight: bold;
  color: #ffcb26;
  font-family: 'Rye', cursive;
}
#education p
{
  line-height: 2rem;
  color: white;
  font-family: 'Amiri', serif;
  font-size: 1.8vh;
  margin-top: 2vh;
  
}
#education .innerContainers1 p , #education .innerContainers2 p
{
  text-align: center;
  font-size: 1.7vh;
}
#education
{
  background-color: #0a1d2f;
  padding-top: 20vh;
  padding-bottom: 10vh;
  
  border-radius: 0% 0% 0% 0%;
}
#education .headers
{
  color: white;
}
#education .col-12
{
  padding-right: 0vh !important;
}
#education .col-12:first-of-type
{
  border-right: solid 0.2vh white;
}
#recentPortfolio strong, #recentPortfolio p
{
  color: white;
}
#recentPortfolio h2
{
  font-size: 3rem;
  padding-top: 6rem !important;
  border-top: solid 0.2rem rgb(255, 193, 36);
  margin-bottom: 2rem !important;
}
#recentPortfolio
{
  padding-bottom: 8rem;
}
#description
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
}
#contact
{
  background-image: url('../images/AdobeStock_248175189.jpg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
#contact .mask
{
  padding-left: 5rem;
  padding-right: 5rem;
}
#contact h2
{
  color: white;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  margin-bottom: 1rem;
  
}
#contact p
{
  line-height: 2rem;
  font-size: 1.1rem;
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#contact label
{
  margin-bottom: 0rem;
}
#contact span 
{
  color: red;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  
  
}
#formPadding
{
  padding-left: 2rem !important;
}
#socialsBanner 
{
  background-color: #ffcb26;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 1vh;
}
#socialsBanner h1
{
  font-size: 2rem !important;
  font-family: 'Amatic SC', cursive;
  color: white;
  padding-top: unset;
}
#footer
{
  background-image: url('../images/AdobeStock_212352434\ copy.jpg');
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
#footer .mask
{
  padding-top: 30vh;
  padding-bottom: 30vh;
}
#footer h2 
{
  color: white;
  font-weight: bold;
  font-size: 5rem;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

#footer p 
{
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
#footer h1
{
  font-family: 'Sacramento', cursive;
  color: white;
  margin-top: 3rem;
}
#subscribe .copywrite{
  padding-right: 0 !important;
}
#subscribe .footerLinks{
  font-size: 1.4vh;
}

}




@media (min-width: 992px) { 

.nav-link
{
  font-weight: normal !important;
  padding-left: 3vh !important;
}
.nav-link:hover
{
  color: white !important;
}
.navbar-brand
{
  font-size: 2vh;
  padding-right: 6vh;
}
#mynav .fa-2x
{
  font-size: 2vh;
  line-height: 4vh;
}
  div p, h1
  {
    color: rgb(0, 0, 0);
  
  }
  #welcome-container p
  {
    font-size: 0.5rem;
    margin-bottom: 0em;
    line-height: 0em;
    
  }
  #seemore
  {
    color: white;
  }
  
  #welcome-container
  {
    padding-left: 8vh;
    padding-top: 15vh;
    height: 45vh;
    
    
  }
  #button-container
  {
    height: 45vh;
   
  }
  .Typist{
    font-family: athelas;
    font-size: 5rem;
    line-height: normal;
    padding-top: 2.5vh;
  }
  #welcome-container p{
    font-family: 'Cinzel', serif;
    font-size: 2rem;
    padding-bottom: 0rem;
    line-height: 6rem;
  }
  #subtitle {
    font-size: 2rem !important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: normal;
    color:#f7d406;
  }
  #profile
  {
    height: 25rem;
    width: 25rem;

  }
  .text
  {
    color: rgb(0, 0, 0);
    margin-bottom: 0em;
    line-height: 2.2rem;
  }
  #about
  {
    background-image: url('../images/about1\ copy.jpg');
    background-position: -21vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
    position: sticky !important;
    
    background-color: #56575b;
    padding-top: 0vh;
    padding-bottom: 0em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 00% 0% 00% 0%;
    height: 90vh;
  }
  #about p 
  {
    font-size: 2vh;
  }
  .profileText .headers, .profileText h2
  {
    color: black;
  }
  .profileText p
  {
    color: #A01F24;
  }
  .headers
  {
    padding-top: 1rem !important;
    font-size: 1.5em;
    margin-bottom: 2vh;
    text-align: start;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: 'Amiri', serif;
    border-bottom: solid 0.2vh #ffcb26;
    padding-bottom: 1vh;
    padding-right: 3vh;
    width: fit-content;
    margin-left: 0vh  !important;
  }
  h2 
  {
    font-size: 2.5vh;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
  }
  .profileText
  {
    
    background-color: #ffcb26;
    padding-top: 23vh;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 10vh;
    width: 45vh;
    margin-right: 1vh;
  }
  h2 
  {
    font-size: 2em;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
    
  #skills
  {
    background-image: url('../images/AdobeStock_305233591.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0% 0% 0% 0%;
  }
  /* #skills .headers
  {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  } */
  #skills .mask
  {
    padding-top: 15vh;
    padding-bottom: 15vh;
    padding: 15vh;
  }
  /* #skills .headers
  {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 1rem;
  } */
  #skills .container
  {
    padding-left: 0rem;
    padding-top: 1rem;
    padding-right: 0rem;
  }
  #skills h3
  {
    color: rgb(255, 255, 255);
    text-align: start;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  #skills h1
  {
    color: rgb(255, 255, 255);
    text-align: start;
    font-weight: bold;
    padding-top: 1.5rem;
    
  }
  #skills h2
  {
    color: rgb(255, 255, 255);
    margin-top: 1rem;
    font-size: 2vh;
    text-align: start;
    
    
  }
  #skills .row
  {
    margin-top: 0em;
    
  }
  #instagram-btn
  {
    margin: 0rem;
    padding: 0.5rem;
    border-radius: 50%;
    width: fit-content;
   margin-top: 0em;
   
  }
  #email
  {
    color: black;
    margin-left: 0rem;
    font-weight: bold;
    font-size: 1.5vh;
    width: fit-content;
    line-height: unset;
    
  }
  #socialEmail
  {
    width: fit-content;
  }
  #email:hover
  {
    
    color: #A01F24;
  }
  #interestsBanner
  {
    background-color: white;
   
   
    border-radius: 0% 0% 0% 0%;

  }
  .innerContainers1
  {
   
  }
  #interestsBanner .innerContainers2
  {
    background-color:rgb(255, 193, 36) ;
    border-radius: 5% 5%;
    box-shadow: 0px 0px 20px 0px rgb(170, 170, 170);
  }

  .interestHeaders
{
  margin-top: 1rem;
  font-size: 1.5rem;

}
.bg-warning
{
  background-color: #ffb300;
}
.blockquote
{
  border:unset;
 

}
.blockquote p
{
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  
}
#grey
{
  background-color: rgb(63, 61, 61);
  
  
}
#quote
{
  background-color: white;
  
  
  margin-left: 18rem;
  margin-right: 18rem;
  
  
}
.blockquote-footer
{
  margin-bottom: 0rem !important; 
  color: rgb(255, 193, 36);
}
.card-text
{
  line-height: 2rem;
  color: white;
}
#recent-blog-text
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-weight: normal;
  font-size: 4rem;
  
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-top: solid 0.2rem #ffb300;
}
#recentBlog #blogComponent a 
{
  font-size: 1.5vh !important;
}
#recentBlog a:hover
{
  color: #ffcb26;
  transform: translateX(10px);
}
#recentBlog #blogComponent h1
{
  font-size: 1.8vh;
}

.card-container
{
  
  padding-bottom: 4rem;
  
}
.card-mask:hover
{
  background-color: black;
  opacity: 0.8;
}
#education h2
{
  font-size: 5rem;
  font-weight: normal;
  text-align: start;
  font-family: 'Cormorant', serif;
  color: white;
}
#education strong
{
  font-size: 6rem;
  font-weight: bold;
  color: #ffcb26;
  font-family: 'Rye', cursive;
}
#education p
{
  line-height: 2rem;
  color: white;
  font-family: 'Amiri', serif;
  font-size: 1.8vh;
  margin-top: 2vh;
  
}
#education .innerContainers1 p , #education .innerContainers2 p
{
  text-align: center;
  font-size: 1.7vh;
}
#education
{
  background-color: #0a1d2f;
  padding-top: 20vh;
  padding-bottom: 10vh;
  
  border-radius: 0% 0% 0% 0%;
}
#education .headers
{
  color: white;
}
#education .col-12
{
  padding-right: 0vh !important;
}
#education .col-12:first-of-type
{
  border-right: solid 0.2vh white;
}
#recentPortfolio strong, #recentPortfolio p
{
  color: white;
}
#recentPortfolio h2
{
  font-size: 3rem;
  padding-top: 6rem !important;
  border-top: solid 0.2rem rgb(255, 193, 36);
  margin-bottom: 2rem !important;
}
#recentPortfolio
{
  padding-bottom: 8rem;
}
#description
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
}
#contact
{
  background-image: url('../images/AdobeStock_248175189.jpg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
#contact .mask
{
  padding-left: 10rem;
  padding-right: 10rem;
}
#contact h2
{
  color: white;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  margin-bottom: 2rem;
  
}
#contact p
{
  line-height: 2rem;
  font-size: 1.3rem;
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#contact label
{
  margin-bottom: 0rem;
}
#contact span 
{
  color: red;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  
  
}
#socialsBanner 
{
  background-color: #ffcb26;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 1vh;
}
#socialsBanner h1
{
  font-size: 4rem !important;
  font-family: 'Amatic SC', cursive;
  color: white;
  padding-top: unset;
}
#footer
{
  background-image: url('../images/AdobeStock_212352434\ copy.jpg');
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#footer .mask
{
  padding-top: 30vh;
  padding-bottom: 30vh;
}
#footer h2 
{
  color: white;
  font-weight: bold;
  font-size: 5rem;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

#footer p 
{
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
#footer h1
{
  font-family: 'Sacramento', cursive;
  color: white;
  margin-top: 3rem;
}
#subscribe .copywrite{
  padding-right: 0 !important;
}
#subscribe .footerLinks{
  font-size: 1.4vh;
}

}


@media (min-width: 1200px) { 
  div p, h1
  {
    color: rgb(0, 0, 0);
  
  }
  #welcome-container p
  {
    font-size: 0.5rem;
    margin-bottom: 0em;
    line-height: 0em;
    
  }
  #seemore
  {
    color:#f7d406 ;
  }
  
  #welcome-container
  {
    padding-left: 0vh;
    padding-right: 0vh;
    padding-top: 0vh;
    height: 45vh;
    
    
  }
  #welcome-container .row
  {
    width: 100%;
    margin: 0em;
  }
  .subtitle-container
  {
    
    padding-top: 20vh;
    height: 32vh;
  }
  #button-container
  {
    height: 15vh;
    
   
  }
  .Typist{
    font-family: athelas;
    font-size: 5rem;
    line-height: normal;
  }
  #welcome-container p{
    font-family: 'Cinzel', serif;
    font-size: 2rem;
    padding-bottom: 0rem;
    line-height: 6rem;
  }
  #subtitle {
    font-size: 1.8rem !important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: normal;
    color:#f7d406;
  }
  #profile
  {
    height: 68vh;
    width: auto;
    margin-top: 0vh;
    

  }
  .text
  {
    color: #A01F24;
    margin-bottom: 0em;
    line-height: 2.2rem;
    font-family: 'Amiri', serif;
    font-size: 2.2vh !important;
  }
  #about
  {
    background-image: url('../images/about1\ copy.jpg');
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
    position: sticky !important;
    
    background-color: #56575b;
    padding-top: 0vh;
    padding-bottom: 0em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 00% 0% 00% 0%;
    height: 90vh;
  }
  .profileText .headers, .profileText h2
  {
    color: white;
  }
  .profileText p
  {
    color: #ffcb26;
  }
  .headers
  {
    padding-top: 1rem !important;
    font-size: 1.5em;
    margin-bottom: 2vh;
    text-align: start;
    color: white;
    font-weight: bold;
    font-family: 'Amiri', serif;
    border-bottom: solid 0.2vh #ffcb26;
    padding-bottom: 1vh;
    padding-right: 3vh;
    width: fit-content;
    margin-left: 0vh  !important;
  }
  h2 
  {
    font-size: 2.5vh;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
  }
  .profileText
  {
    
    background-color: #06203c;
    padding-top: 28vh;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 10vh;
    width: 70vh;
    margin-right: 20vh;
  }
  
    
  #skills
  {
    background-image: url('../images/AdobeStock_305233591.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0% 0% 0% 0%;
   
    
  }
  #skills .mask
  {
    padding-top: 15vh;
    padding-bottom: 15vh;
    padding: 15vh;
  }
  /* #skills .headers
  {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: start;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 1rem;
  } */
  #skills .container
  {
    padding-left: 0rem;
    padding-top: 1rem;
    padding-right: 0rem;
  }
  #skills h3
  {
    color: rgb(255, 255, 255);
    text-align: start;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  #skills h1
  {
    color: rgb(255, 255, 255);
    text-align: start;
    font-weight: bold;
    padding-top: 1.5rem;
    
  }
  #skills h2
  {
    color: rgb(255, 255, 255);
    margin-top: 1rem;
    font-size: 2vh;
    text-align: start;
    
    
  }
  #skills .row
  {
    margin-top: 0em;
    
  }
  #instagram-btn
  {
    margin: 0rem;
    box-shadow: none;
    border-radius: 50%;
    width: 5vh;
    height: 5vh;
   margin-top: 0em;
   
  }
  #email
  {
    color: #ffcb26;
    margin-left: 0rem;
    font-weight: bold;
    font-size: 2vh;
    width: fit-content;
    line-height: unset;
    
    
  }
  #email:hover
  {
    
    color: #A01F24;
  }
  #interestsBanner
  {
    background-color: transparent;
   
    
    border-radius: 0% 0% 0% 0%;

  }
  .innerContainers1
  {
   
    
    
  }
  #interestsBanner .innerContainers2
  {
    background-color: transparent;
    border-radius: unset;
    box-shadow: unset;
    padding: 3vh !important;
    
    
  }

  .interestHeaders
{
  margin-top: 3vh;
  font-size: 2.8vh;
  color: #ffcb26;
  font-family: 'Amiri', serif;

}
.bg-warning
{
  background-color: #ffb300;
}
.blockquote
{
  border:unset;
 

}
.blockquote p
{
  text-align: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  
}
#grey
{
  background-color: rgb(63, 61, 61);
  
  
}
#quote
{
  background-color: white;
  
 
  margin-left: 18rem;
  margin-right: 18rem;
  
  
}
.blockquote-footer
{
  margin-bottom: 0rem !important; 
  color: rgb(255, 193, 36);
}
.card-text
{
  line-height: 2rem;
  color: white;
}
#recent-blog-text
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-weight: normal;
  font-size: 4rem;
  
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-top: unset;
}
#recentBlog .mask
{
  padding-top: 25vh;
  padding-bottom: 25vh;
}
#recentBlog #blogComponent
{
    padding-top: 5vh;
    padding-bottom: 1vh !important;
    border-bottom: solid 0.1vh #f7cd52;
    margin-left: 15px;
    margin-right: 15px;
}
#recentBlog #blogComponent h1, #recentBlog #blogComponent li, #recentBlog #blogComponent p
{
  color: white;
}

#recentBlog #blogComponent h1
{
  font-size: 2.2vh;
}
#recentBlog h1
{
  margin-top: 2vh;
  font-size: 3vh;
}
#recentBlog li
{
  margin-left: 1vh;
}
#recentBlog a{
  color: white;
  font-size: 2vh;
  font-family: 'Amiri', serif;
  margin-top: 0vh;
}
#recentBlog #blogComponent a 
{
  font-size: 1.5vh !important;
}
#recentBlog a:hover
{
  color: #ffcb26;
  transform: translateX(10px);
}
.card-container
{
  
  padding-bottom: 4rem;
  
}
.card-mask:hover
{
  background-color: black;
  opacity: 0.8;
}
#education h2
{
  font-size: 5rem;
  font-weight: normal;
  text-align: start;
  font-family: 'Cormorant', serif;
  color: white;
}
#education strong
{
  font-size: 6rem;
  font-weight: bold;
  color: #ffcb26;
  font-family: 'Rye', cursive;
}
#education p
{
  line-height: 2rem;
  color: white;
  font-family: 'Amiri', serif;
  font-size: 1.8vh;
  margin-top: 2vh;
  
}
#education .innerContainers1 p , #education .innerContainers2 p
{
  text-align: center;
  font-size: 1.7vh;
}
#education
{
  background-color: #0a1d2f;
  padding-top: 20vh;
  padding-bottom: 10vh;
  
  border-radius: 0% 0% 0% 0%;
}
#education .headers
{
  color: white;
}
#education .col-12
{
  padding-right: 10vh !important;
}
#education .col-12:first-of-type
{
  border-right: solid 0.2vh white;
}

#recentPortfolio strong, #recentPortfolio p
{
  color: white;
}
#recentPortfolio h2
{
  font-size: 3rem;
  padding-top: 6rem !important;
  border-top: unset;
  margin-bottom: 2rem !important;
}
#recentPortfolio
{
  padding-bottom: 8rem;
}
#description
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
}
#contact
{
  background-image: url('../images/AdobeStock_248175189.jpg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
#contact .mask
{
  padding-left: 3rem;
  padding-right: 3rem;
 
}
#contact h2
{
  color: white;
  font-size: 4.3rem;
  font-family: 'Playfair Display', serif;
  margin-bottom: 1rem;
  
}
#contact p
{
  line-height: 2rem;
  font-size: 1rem;
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#contact label
{
  margin-bottom: 0rem;
}
#contact span 
{
  color: red;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  
  
}
#socialsBanner 
{
  background-color: #06203c;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 0vh;
}
#socialsBanner h1
{
  font-size: 4rem !important;
  font-family: 'Amatic SC', cursive;
  color: white;
  padding-top: unset;
}
#footer
{
  background-image: url('../images/AdobeStock_212352434\ copy.jpg');
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#footer .mask
{
  padding-top: 30vh;
  padding-bottom: 30vh;
}
#footer h2 
{
  color: white;
  font-weight: bold;
  font-size: 5rem;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

#footer p 
{
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
#footer h1
{
  font-family: 'Sacramento', cursive;
  color: white;
  margin-top: 3rem;
}
#socialsBanner .col-12
{
  border-left: solid 0.1rem whitesmoke !important;
}

#subscribe{
  padding-bottom: 5vh;
  padding-top: 10vh;
}

#subscribe label {
  
  text-align: left;
  font-size: 1.4vh;
}
#subscribe .col-12
{
  padding-left: 0;
  padding-right: 0vh;
}
#subscribe p {
  font-size: 1.4vh;
}
#subscribe .footerSocials
{
  color: #ffcb26;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4vh;
  margin-bottom: 1vh;
}
#subscribe .col-12 p {
  padding-right: 8vh;
}
#subscribe .copywrite{
  padding-right: 0 !important;
}
#subscribe .footerLinks{
  font-size: 1.4vh;
}


}
/*.row, #profile, #about, .text, .container, .headers, .col-12
  {
    border: solid 0.3em white !important;

  }
*/
#seemore
{
  font-size: 0.8rem;
}

.open
  {
    transform: rotate(90deg);
  }

.Typist
{
  color: white;
  
  
      
  
}

.flash
  {

  
  animation-name: blinker;
      animation-duration: 0.7s;
      animation-timing-function: linear;
    
      
  }
  @keyframes blinker {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  
  strong
  {
    color: #ffcb26 ;
    
  }
  @media (hover: none)  {
    .bg {
      background-image: url('../images/BackgroundFinal\(1\).jpg');
      height: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: unset;
      
      }
    #about, #skills, #contact, #footer
    {
      background-attachment: unset;
      

    }
    #about .mask
    {
      background-color: #212529e3;
    }
    @media (min-width: 768px)
    {
      #about{
        background-attachment: unset;
        background-position: -237px 0px !important;

      }
      

    }

    
    
}